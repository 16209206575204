import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {
        const {textColor, firstName, lastName, title} = this.props
        let menu = this.props.menu
        if(this.props.filter) {
            menu = this.props.menu.filter((item) => {
                return (!this.props.filter.includes(item.name))
            })
        }
        return (
            <div className="footer row pt-md-5 pb-5 justify-content-center" style={this.props.style}>
                <div className='col-12'>
                    <div className='row justify-content-around align-items-center '>
                        <div className="col-12 col-lg-auto mt-5 mt-lg-0 order-last order-lg-first text-center text-lg-left" aria-label="Footer Menu">
                            <div style={{color: textColor, fontSize: '1.15rem'}} className='text-center'>
                                © Copyright – {`${firstName} ${lastName}`}, {title}
                            </div>
                            <div style={{color: textColor}} className='small mt-2 text-center text-muted'>
                                <span>Designed & Developed by </span>
                                <a target="_blank" rel="noopener noreferrer"
                                   href="https://www.anovatech.ca"
                                   style={{color: textColor}}
                                >
                                    Anova Technology
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-auto text-center" aria-label="Footer Menu">
                            <div className="row justify-content-center">
                                {
                                    menu.map((menuItem) => {
                                        return (
                                            <div key={menuItem.title} className="col-6 col-md mt-5 mt-md-0 ">
                                                <Link to={menuItem.url} style={{color: textColor}}>
                                                    <u>{menuItem.title}</u>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                                {/*<div className="col-6 col-md mt-5 mt-md-0">*/}
                                {/*    <a target="_blank" rel="noopener noreferrer"*/}
                                {/*       href='https://sonyascheer.janeapp.com'*/}
                                {/*       style={{color: textColor}}*/}
                                {/*    >*/}
                                {/*        <u>Booking</u>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                                <div className="col-6 col-md mt-5 mt-md-0">
                                    <Link to='/admin' style={{color: textColor}}>
                                        <u>Admin</u>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
