import React from 'react'
import {resourceHeight} from '../util'

export default class SellingPoints extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const sectionColWidth = 12
        const sections = [
            {url: 'https://ac-hu-sonya.s3-us-west-1.amazonaws.com/stack_stone_image_2.jpeg', title: 'Strength & Balance'},
            {url: 'https://ac-hu-sonya.s3-us-west-1.amazonaws.com/massage_image_1.jpg', title: 'Client Focus'},
            {url: 'https://ac-hu-sonya.s3-us-west-1.amazonaws.com/muscle1.jpg', title: 'Professional'}]

        return (
            <div className="row justify-content-center align-items-center text-right" style={this.props.style}>
                {
                    sections.map((section) => {
                        return (
                            <div key={section.title} className="col-10 col-sm-4 mt-3 mt-lg-0 p-0 text-center">
                                <img
                                    src={section.url}
                                    style={{
                                        width: '100%',
                                        height: `${resourceHeight(3, sectionColWidth, window.innerWidth > 500 ? 0.625 : 1)}vw`,
                                        minHeight: '200px',
                                        maxHeight: '350px',
                                        border: `1px solid ${this.props.color.textGray}`,
                                    }}
                                />
                                {section.title}
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}
