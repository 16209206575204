import React from 'react'
import TopNav from './TopNav'

export default class Content1 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className='d-none d-md-block'>
                    <div className="row justify-content-end align-items-center bg-dark text-white" style={this.props.style}>
                        <div className="col-12 col-md-6">
                            <div className='ml-4'>
                                <h2>Therapeutic Massage</h2>
                                <p className='mt-4'>
                                    Massage therapy relaxes muscle tissue, which reduces painful contractions and spasms. Massage can also reduce
                                    nerve
                                    compression.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mt-4 mt-md-0 p-0" style={{
                            backgroundImage: `url('https://ac-hu-sonya.s3-us-west-1.amazonaws.com/flower_image_4.jpeg')`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            height: this.props.style.height,
                            minHeight: this.props.style.minHeight,
                            maxHeight: this.props.style.maxHeight,
                        }}>
                            {/*<img src="https://ac-hu-sonya.s3-us-west-1.amazonaws.com/flower_image_4.jpeg" alt=""/>*/}
                        </div>
                    </div>
                </div>
                <div className='d-md-none'>
                    <div className='row' style={{
                        backgroundImage: `url('https://ac-hu-sonya.s3-us-west-1.amazonaws.com/flower_image_4.jpeg')`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        height: this.props.style.height,
                        minHeight: this.props.style.minHeight,
                        maxHeight: this.props.style.maxHeight,
                    }}>
                        <div className='col-12 h-100'>
                            <div className='row align-items-center h-100'>
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: 'black',
                                    opacity: 0.35
                                }}/>
                                <div className='col-12 text-center text-white align-content-center'>
                                    <div>
                                        <h2 style={{zIndex: 100}}>Therapeutic Massage</h2>
                                        <p className='mt-4' style={{zIndex: 10}}>
                                            Massage therapy relaxes muscle tissue, which reduces painful contractions and spasms. Massage can also
                                            reduce nerve
                                            compression.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
