import React from 'react'
import {withRouter} from 'react-router-dom'
import TopNav from '../shared/TopNav'
import Footer from '../shared/Footer'
import CircularProgress from '@material-ui/core/CircularProgress';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import Divider from "@material-ui/core/Divider";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RecordingIndexView from "./RecordingIndexView";
import RecordingAllView from "./RecordingAllView";

class RecordingPageFrame extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            recordings: [],
            orderBy: this.props.orderBy
        }

        this.topNav = this.topNav.bind(this)
        this.getRecordings = this.getRecordings.bind(this)
        this.getCategories = this.getCategories.bind(this)
    }

    getCategories() {
        return fetch(`${this.props.serviceUrl}/categories`, {
            credentials: 'same-origin',
            // credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.props.authData ? this.props.authData.signInUserSession.accessToken.jwtToken : ''
            },
            method: 'get'
        }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({categories: data})
        })
    }

    getRecordings() {
        return fetch(`${this.props.serviceUrl}/recordings`, {
            credentials: 'same-origin',
            // credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.props.authData ? this.props.authData.signInUserSession.accessToken.jwtToken : ''
            },
            method: 'get'
        }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({recordings: data})
        })
    }

    divider(sectionColWidth) {
        return (
            <div className='row justify-content-center'>
                <div className={`col-12 col-md-${sectionColWidth} pt-4 p-0`}>
                    <Divider variant="middle" className='ml-0 mr-0'/>
                </div>
            </div>
        )
    }


    componentDidMount() {
        this.getRecordings()
        this.getCategories()
        window.scrollTo(0, 0)
    }

    topNav() {
        return (
            <TopNav {...this.props}/>
        )
    }

    render() {
        if (this.state.recordings.length == 0 || this.state.categories.length == 0) {
            return (
                <div className='container-fluid p-0'>
                    <div className='row justify-content-center align-items-center' style={{height: '100vh'}}>
                        <CircularProgress/>
                    </div>
                </div>
            )
        }

        return (
            <div className='container-fluid' style={{color: this.props.color.textGray}}>
                {this.topNav()}
                {this.divider(12)}
                <div className="row justify-content-center pt-4 pb-4" style={{backgroundColor: this.props.color.lightGray}}>
                    <div className="col-12 col-md-8 text-center">
                        <h2 className="has-text-align-center">
                            <OndemandVideoIcon fontSize='large' className='mr-1'/>
                            Videos
                        </h2>
                        <p className='mt-4'>Stretching and strengthening tips for current patients or the general public interested in maintaining
                            healthy bodies.</p>
                    </div>
                </div>

                <div className='row'>
                    <AppBar position="static" color='default' style={{backgroundColor: this.props.color.darkGray}}>
                        <Tabs
                            className='mt-2 mb-2'
                            value={this.state.orderBy}
                            aria-label="video sort order tabs"
                            indicatorColor="primary"
                            // onChange={(e, value)=> { this.setState({orderBy: value})}}
                            centered
                        >
                            <Tab
                                label={<u>Watch All Videos</u>}
                                value='all'
                                onClick={() => {
                                    this.props.history.push('/videos')
                                }}
                                component='a'
                            />
                            <Tab
                                label={<u>Watch By Topics</u>}
                                value='topics'
                                onClick={() => {
                                    this.props.history.push('/topics')
                                }}
                                component='a'
                            />
                            <Tab
                                label={<u>exit</u>}
                                value='exit'
                                component='a'
                                href='/#videos'
                            />
                        </Tabs>
                        <div className='row justify-content-center m-0' style={{backgroundColor: this.props.color.darkGray}}>
                        <div className='col-12 p-0'>
                            {
                                this.state.orderBy === 'topics' ?
                                    <RecordingIndexView
                                        {...this.props}
                                        recordings={this.state.recordings}
                                        categories={this.state.categories}
                                    />
                                    :
                                    <RecordingAllView
                                        {...this.props}
                                        recordings={this.state.recordings}
                                        categories={this.state.categories}
                                    />
                            }
                        </div>

                        </div>
                    </AppBar>
                </div>
                <Footer {...this.props}
                        filter={['about', 'videos', 'contact', 'booking']}
                        style={{backgroundColor: '#343a40'}}/>

            </div>
        )
    }
}

export default withRouter(RecordingPageFrame)
