import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dropzone from 'dropzone'
import ReactTags from 'react-tag-autocomplete'
import LinearProgress from '@material-ui/core/LinearProgress';
import {Typography} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from "@material-ui/core/CircularProgress";

export default class RecordingUpdateView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            missingTitleError: false,
            saved: false,
            loading: false,
            upload: false,
            blockSave: false,
            recording: this.props.recording,
            suggestions: this.props.categories
        }

        this.typingTimer;
        this.doneTypingInterval = 1000;

        this.delete = this.delete.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleAddition = this.handleAddition.bind(this)
        this.delayedUpdateRecording = this.delayedUpdateRecording.bind(this)
        this.updateRecording = this.updateRecording.bind(this)
    }

    componentDidMount() {
        const _self = this
        if (document.getElementById('upload')) {
            new Dropzone(`#upload`, {
                url: `${this.props.serviceUrl}/recordings/${this.props.recording.id}/upload`,
                headers: {
                    'Access-Token': this.props.authData.signInUserSession.accessToken.jwtToken
                },
                autoQueue: true,
                autoProcessQueue: true,
                uploadMultiple: false,
                parallelUploads: 100,
                maxFiles: 1,
                resizeWidth: 200,
                dictDefaultMessage: 'Select/Drop file here to upload.',
                paramName: 'file', // The name that will be used to transfer the file
                maxFilesize: 50, // MB
                addRemoveLinks: true,
                acceptedFiles: '.mp4,.mov',
                accept: function (file, done) {
                    _self.setState({
                        loading: true,
                        blockSave: true
                    })
                    done()
                },
                init: function () {
                    let dzHandler = this;

                    this.on("maxfilesexceeded", function (file) {
                        dzHandler.removeAllFiles();
                        dzHandler.emit("addedfile", file)
                    });
                    this.on('success', function (files, data) {
                        _self.setState({
                            recording: data,
                            loading: false,
                            blockSave: false,
                            saved: true,
                        })
                        dzHandler.removeAllFiles();
                        this.typingTimer = setTimeout(() => {
                            _self.setState({saved: false})
                        }, this.doneTypingInterval);
                    });
                }

            })
        }
    }

    delete() {
        this.setState({blockSave: true})
        return fetch(`${this.props.serviceUrl}/recordings/${this.props.recording.id}`, {
            credentials: 'same-origin',
            // credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.props.authData ? this.props.authData.signInUserSession.accessToken.jwtToken : ''
            },
            method: 'delete'
        }).then((response) => {
            this.props.close(true)
            return
        })
    }

    delayedUpdateRecording(body) {
        const _self = this
        let recording = Object.assign(this.state.recording, body)
        clearTimeout(this.typingTimer)
        this.setState({recording: recording})
        this.typingTimer = setTimeout(() => {
            _self.updateRecording(body, false)
        }, this.doneTypingInterval);
    }

    updateRecording(body, updateState = true) {
        const _self = this
        this.setState({loading: true})
        return fetch(`${this.props.serviceUrl}/recordings/${this.props.recording.id}`, {
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.props.authData ? this.props.authData.signInUserSession.accessToken.jwtToken : ''
            },
            body: JSON.stringify(body),
            method: 'put'
        }).then((response) => {
            return response.json()
        }).then((data) => {
            let newState =
                {
                    saved: true,
                    loading: false,
                }

            if (updateState) {
                newState.recording = data
            }

            this.setState(newState)
            clearTimeout(this.typingTimer)
            this.typingTimer = setTimeout(() => {
                _self.setState({saved: false})
            }, this.doneTypingInterval);
        })
    }

    handleDelete(i) {
        this.updateRecording({removed_category: {id: this.state.recording.categories[i].id}})
    }

    handleAddition(tag) {
        this.updateRecording({new_category: tag})
    }

    render() {
        const {recording, loading, saved, blockSave} = this.state
        const width = 'mt-4 col-12 col-lg-8'

        return (
            <div style={{width: '90vw'}}>
                {
                    blockSave &&
                    <div className='h-100 w-100'
                         style={{
                             position: 'fixed',
                             top: 0,
                             left: 0,
                             bottom: 0,
                             right: 0,
                             zIndex: 99998,
                         }}>
                        <div className='row justify-content-center align-items-center h-100 w-100'>
                            <CircularProgress size={50} style={{zIndex: 99999}}/>
                            <div
                                className='h-100 w-100'
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    backgroundColor: this.props.color.lightGray,
                                    opacity: 0.75,
                                }}>
                            </div>
                        </div>
                    </div>
                }
                <AppBar color="default" position="static">
                    <Toolbar>
                        <IconButton
                            onClick={this.props.close}
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Button
                            variant="outlined"
                            style={{color: 'red'}}
                            onClick={this.delete}
                            style={{marginLeft: 'auto'}}
                        >
                            Delete
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className='container-fluid w-100'>
                    <div className='row justify-content-center'>
                        <div className={width}>
                            <ReactTags
                                tags={this.state.recording.categories || []}
                                suggestions={this.state.suggestions}
                                handleDelete={this.handleDelete.bind(this)}
                                handleAddition={this.handleAddition.bind(this)}
                                minQueryLength={1}
                                maxSuggestionsLength={50}
                                allowNew={true}
                                addOnBlur={true}
                            />
                        </div>
                        <div className={width}>
                            <FormControl variant="outlined" style={{width: '100%'}}>
                                <InputLabel
                                    htmlFor="format b-0"
                                    style={{backgroundColor: 'white'}}
                                >
                                    Format
                                </InputLabel>
                                <Select
                                    value={recording.format || 'file'}
                                    onChange={(event) => {
                                        this.updateRecording({format: event.target.value})
                                    }}
                                    inputProps={{
                                        name: 'format',
                                        id: 'format',
                                    }}
                                >
                                    <MenuItem value={'file'}>Video File</MenuItem>
                                    <MenuItem value={'youtube'}>Youtube</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {
                            recording.url && <div className={width}>
                                <InputLabel>Preview</InputLabel>
                                {
                                    recording.format == 'youtube' ?
                                        <iframe
                                            width={'100%'}
                                            height={window.innerWidth <= 500 ? '200px' : '512px'}
                                            src={recording.url}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        />
                                        :
                                        <div className='col-12' style={{
                                            position: 'relative',
                                            paddingBottom: '56.25%',
                                            height: 0,
                                        }}>
                                            <video preload="true" disablePictureInPicture controls controlsList="nodownload"
                                                   style={{
                                                       position: 'absolute',
                                                       top: 0,
                                                       left: 0,
                                                       width: '100%',
                                                       height: '100%',
                                                   }}>
                                                <source src={recording.url + '#t=0.1'} type="video/mp4"/>
                                            </video>
                                        </div>
                                }
                            </div>
                        }
                        <div className={width}>
                            <form className="dropzone w-100" id='upload' action='#'
                                  style={{display: recording.format == 'file' ? '' : 'none'}}>
                                <div id="dropzone-previews"></div>
                            </form>

                            {
                                recording.format == 'youtube' && <TextField
                                    className='w-100'
                                    id="outlined-name"
                                    label="Url"
                                    value={recording.url || 'https://www.youtube.com/embed/'}
                                    onChange={(event) => {
                                        this.delayedUpdateRecording({url: event.target.value})
                                    }}
                                    InputLabelProps={{shrink: true}}
                                    placeholder='Youtube embed video url'
                                    margin="normal"
                                    variant="outlined"
                                />
                            }
                        </div>
                        <div className={width}>
                            <TextField
                                className='w-100'
                                id="outlined-name"
                                label="Title"
                                value={recording.name || ''}
                                onChange={(event) => {
                                    const payload = {name: event.target.value}
                                    if (/^ *$/.test(event.target.value)) {
                                        clearTimeout(this.typingTimer)
                                        let recording = Object.assign(this.state.recording, payload)
                                        this.setState({
                                            loading: false,
                                            missingTitleError: true,
                                            recording: recording
                                        })
                                    } else {
                                        this.setState({missingTitleError: false})
                                        this.delayedUpdateRecording(payload)
                                    }
                                }}
                                error={this.state.missingTitleError}
                                helperText={this.state.missingTitleError ? 'Can not be blank.' : ''}
                                InputLabelProps={{shrink: true}}
                                placeholder='Video title'
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div className={width}>
                            <TextField
                                className='w-100'
                                label="Description"
                                margin="normal"
                                variant="outlined"
                                value={recording.description || ''}
                                multiline
                                rowsMax="4"
                                InputLabelProps={{shrink: true}}
                                placeholder='Video descriptions'
                                onChange={(event) => {
                                    this.delayedUpdateRecording({description: event.target.value})
                                }}
                            />
                        </div>
                        <div className={width}/>
                    </div>
                    <div className='row' style={{
                        position: 'fixed',
                        bottom: 0,
                        width: '90vw',
                        backgroundColor: 'transparent',
                        height: '50px',
                        zIndex: 99999
                    }}>
                        <div className='col-12 pl-0 text-right' style={{height: '40px' }}>
                            <Typography
                                style={{
                                    marginLeft: 'auto',
                                    paddingTop: '2.5px',
                                    paddingRight: '30px',
                                }}>
                                {loading && <SaveIcon color='primary' fontSize='large'/>}
                                {!loading && saved && <SaveIcon fontSize='large' style={{color: 'green'}}/>}
                            </Typography>
                        </div>
                        <div className='col-12 p-0'>
                            {loading && <LinearProgress style={{height: '10px'}}/>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


