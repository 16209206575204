import React from 'react'
import {withRouter} from 'react-router-dom'
import TopNav from '../shared/TopNav2'
import TagLineFullWidth from '../shared/TagLineFullWidth'
import Intro from '../shared/Intro'
import Contact from '../shared/Contact'
import Videos from '../shared/Videos'
import Footer from '../shared/Footer'
import Content1 from '../shared/Content1'
import Map from '../shared/Map'
import SellingPoints from '../shared/SellingPoints'

const maxWidth = '1675px'
const height = window.innerHeight / 3
const minHeight = 300
const maxHeight = minHeight * 2.5

const videoNav = [
    {
        url: 'https://ac-hu-sonya.s3-us-west-1.amazonaws.com/stretching_woman.jpg',
        title: 'Watch All Videos',
        imagePosition: 'center center',
        style: {
            position: 'absolute',
            top: '0',
            bottom: '0',
            right: '15px',
            left: '15px',
            backgroundColor: 'black',
            opacity: '0.33',
            borderRadius: '25px',
        },
        page: '/videos'
    },
    {
        url: 'https://ac-hu-sonya.s3-us-west-1.amazonaws.com/girl-dancing.jpg',
        title: 'Watch by Topics',
        imagePosition: null,
        style: {
            position: 'absolute',
            top: '0',
            bottom: '0',
            right: '15px',
            left: '15px',
            backgroundColor: 'black',
            opacity: '0.33',
            borderRadius: '25px',
        },
        page: '/topics'
    },
]

class IndexView extends React.Component {

    constructor(props) {
        super(props);
    }

    pxHeight(height) {
        return (`${height}px`)
    }

    render() {
        const pxHeight = this.pxHeight(height)
        const pxMinHeight = this.pxHeight(minHeight)
        const pxMaxHeight = this.pxHeight(maxHeight)
        const taglineMaxHeight = window.outerHeight * 0.666
        return (
            <div className='container-fluid' style={{color: '#787878', maxWidth: maxWidth}}>
                <TopNav {...this.props}/>
                <div className='section pt-0'>
                    <TagLineFullWidth
                        {...this.props}
                        image={this.props.image}
                        backgroundPosition={this.props.backgroundPosition}
                        style={{
                            height: this.pxHeight(window.innerHeight),
                            maxHeight: this.pxHeight(taglineMaxHeight > maxHeight ? maxHeight : taglineMaxHeight)
                        }}/>
                    <div id='about'></div>
                </div>
                <div className='section'>
                    <Intro {...this.props} style={{
                        maxWidth: maxWidth,
                    }}/>
                </div>
                <div className='section'>
                    <SellingPoints {...this.props}/>
                </div>
                <div className='section'>
                    <Content1 {...this.props} style={{
                        height: this.pxHeight(window.innerHeight / 2),
                        minHeight: minHeight,
                        maxHeight: pxMaxHeight
                    }}/>
                </div>
                <div className='section'>
                    <Videos videoNav={videoNav}/>
                    <div id='contact'/>
                </div>
                <div style={{paddingBottom: '64px'}}>
                    <Contact {...this.props} />
                </div>
                <div className='section pb-0'>
                    <Map {...this.props} style={{
                        height: pxHeight,
                        minHeight: pxMinHeight,
                        maxHeight: pxMaxHeight
                    }}/>
                </div>
                <div className='section pb-0'>
                    <Footer
                        {...this.props}
                        filter={['about', 'videos', 'contact', 'booking']}
                        style={{
                            backgroundColor: '#343a40',
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(IndexView)
