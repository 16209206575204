import React from 'react'
import ReactDOM from 'react-dom'
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import V1 from '../components/v1/IndexView'
import S1 from '../components/s2/IndexView'
import RecordingPageFrame from '../components/video/RecordingPageFrame'
import AuthenticationView from '../components/admin/AuthenticationView'
import Amplify from 'aws-amplify';
import {Authenticator} from "aws-amplify-react/dist/Auth";
import {SignUp, SignIn, Greetings, ForgotPassword} from "aws-amplify-react";

Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_DzCXDkrNc',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '22tf4dpgnfvg7kiuhm00prgqfa',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: '.yourdomain.com',
        //     // OPTIONAL - Cookie path
        //     path: '/',
        //     // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        //     // OPTIONAL - Cookie secure flag
        //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: true
        // },

        // OPTIONAL - customized storage object
        // storage: new MyStorage(),

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
});

// You can get the current config object
// const currentConfig = Auth.configure();

const props = {
    serviceUrl: 'https://sonyascheer.com',
    // serviceUrl: 'http://localhost:3000',
    // serviceUrl: 'http://192.168.87.120:3000',
    lastName: 'Scheer',
    firstName: 'Sonya',
    subtitle: 'Registered Massage Therapist',
    title: 'RMT',
    address: 'Near Lincoln Ave & Wellington Street in Port Coquitlam',
    email: 'sonya.rmt@gmail.com',
    phone: '(778)866-0139',
    menu: [
        {name: 'home', title: 'Home', url: '/#menu'},
        {name: 'about', title: 'About', url: '/#about'},
        {name: 'videos', title: 'Videos', url: '/videos'},
        {name: 'contact', title: 'Contact', url: '/#contact'},
    ],
    color: {
        white: 'white',
        black: 'black',
        textGray: '#787878',
        lightGray: '#f9f9f9',
        darkGray: '#eeeeee'
    }
}

const theme = createMuiTheme({
    palette: {
        type: 'light',
    },
});

document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route exact path="/" render={() => {
                        return (<V1 {...props} image='https://ac-hu-sonya.s3-us-west-1.amazonaws.com/Buntzen+Lake+in+the+autumn.+Coquitlam.jpg'/>)
                    }}/>
                    <Route exact path="/topics" render={() => {
                        return (
                            <RecordingPageFrame
                                key='topics'
                                {...props}
                                orderBy='topics'
                            />
                        )
                    }}/>
                    <Route exact path="/videos" render={() => {
                        return (
                            <RecordingPageFrame
                                key='all'
                                {...props}
                                orderBy='all'
                            />
                        )
                    }}/>
                    <Route exact path="/admin" render={() => {
                        return (
                            <Authenticator hide={[SignUp, SignIn, Greetings, ForgotPassword]}>
                                <AuthenticationView {...props}/>
                            </Authenticator>
                        )
                    }}/>
                </Switch>
            </Router>
        </MuiThemeProvider>,
        document.body.appendChild(document.createElement('div')),
    )
})

