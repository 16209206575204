import React from 'react'
import {SignIn} from 'aws-amplify-react';
import Footer from "../shared/Footer";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class Login extends SignIn {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            missingPassword: false,
            missingUsername: false,
        }
    }

    showComponent(theme) {
        const classes = makeStyles(theme => ({
            '@global': {
                body: {
                    backgroundColor: theme.palette.common.white,
                },
            },
            paper: {
                marginTop: theme.spacing(8),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            form: {
                width: '100%', // Fix IE 11 issue.
                marginTop: theme.spacing(1),
            },
            submit: {
                margin: theme.spacing(3, 0, 2),
            },
        }))

        const {loading} = this.state
        return (
            <div className='container-fluid' style={{height: '80vh'}}>
                <div className='row align-items-center h-100 sign-in' style={{paddingBottom: '215px'}}>
                    <div className='col-12'>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline/>
                            <div className={classes.paper}>
                                <Typography component="h1" variant="h5">
                                    Sign in
                                </Typography>
                                <form className={classes.form} noValidate>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="username"
                                                key="username"
                                                name="username"
                                                onChange={this.handleInputChange}
                                                type="text"
                                                label="Username"
                                                autoFocus
                                                error={this.state.missingUsername}
                                            />
                                        </div>
                                        <div className='col-12'>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="password"
                                                key="password"
                                                name="password"
                                                onChange={this.handleInputChange}
                                                type="password"
                                                label="Password"
                                                autoComplete="current-password"
                                                error={this.state.missingPassword}
                                            />
                                        </div>
                                        <div className='col-12 mt-4'>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                onClick={() => {
                                                    const username = this.getUsernameFromInput() || ''
                                                    const password = this.inputs.password || ''
                                                    this.setState({
                                                        missingPassword: password === '',
                                                        missingUsername: username === ''
                                                    })

                                                    if (username && password) {
                                                        this.setState({
                                                            missingPassword: false,
                                                            missingUsername: false,
                                                            loading: true
                                                        })
                                                        super.signIn()
                                                    }
                                                }}
                                                disabled={loading}
                                            >
                                                Sign In
                                            </Button>
                                        </div>
                                        <div className='col-12 text-right mt-4'>
                                            <a href="#" variant="body2"
                                               onClick={() => super.changeState("forgotPassword")}>
                                                <u>Forgot password?</u>
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Container>
                    </div>
                </div>
                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: '15px',
                }}>
                    {loading && <LinearProgress style={{height: '10px', marginLeft: '-15px', marginRight: '-15px'}}/>}
                    <Footer
                        {...this.props}
                        filter={['about', 'videos', 'contact', 'booking']}
                        style={{
                            backgroundColor: '#343a40',
                        }}/>
                </div>
            </div>
        );
    }
}

// export default withAuthenticator(Login, true);
