import React from 'react'

export default class Intro extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="row justify-content-center" >
                <div className="col-12" style={{maxWidth: this.props.style.maxWidth}}>
                    <div className="row justify-content-center">
                        <div className='col-12 d-md-none text-center'>
                            <h2>Meet Sonya</h2>
                        </div>
                        <div className='col-5 col-md-6 col-lg-4 p-0 text-center'>
                            <img
                                src="https://ac-hu-sonya.s3-us-west-1.amazonaws.com/headshot2.jpeg"
                                alt=""
                                style={{
                                    width: '100%',
                                    // height: '100%',
                                    maxWidth:'337.5px',
                                    maxHeight: '450px'
                                }}
                            />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 text-center'>
                            <h2 className='d-none d-md-inline-block'>Meet Sonya</h2>
                            <p className='mt-2 ml-2 ml-md-0 text-left'>
                                Sonya comes to the office with 12 years of professional experience, treating a wide variety of concerns from Headaches, Whiplash, Low Back Pain to TMJ issues.
                                Sonya studied ballet for 20 years, has spent time training for 1/2 marathons, teaching pilates and is currently enjoying HIIT workouts.
                                She loves the outdoors, and has completed the West Coast Trail, hiked Machu Picchu and now enjoys spending time hiking local trails with her young family.
                                From her personal experiences with chronic pain and injury, she understands first-hand the benefit of Massage Therapy treatment.
                                Don’t let pain and injury hold you back.
                            </p>

                            <a target="_blank"
                               rel="noopener noreferrer"
                               className={`btn mt-md-2 ${this.props.buttonStyle || 'btn-success '}`}
                               style={this.props.buttonStyle}
                               href='https://sonyascheer.janeapp.com'>
                                <u>Book An Appointment</u>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
