import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

export default class TopNav extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {lastName, firstName, title, menu} = this.props
        return (
            <div id='menu' className='row justify-content-center'>
                <div className='col-12 col-md-10 col-lg-6'>
                    <div className='row justify-content-center mt-4'>

                        <div className="col-12 text-center">
                            <h2>{`${firstName} ${lastName}, ${title}`}</h2>
                            {this.props.subtitle && <span className="site-description">{this.props.subtitle}</span>}
                        </div>
                    </div>
                    <div className='row justify-content-around mt-4 mb-4'>
                        {
                            menu.map((menuItem) => {
                                return (
                                    <div key={menuItem.title} className='col text-center mt-2 mt-md-0'>
                                        <Link to={menuItem.url}>
                                            <u>{menuItem.title}</u>
                                        </Link>
                                    </div>
                                )
                            })
                        }

                        <div className='col text-center mt-2 mt-md-0'>
                            <a target="_blank" rel="noopener noreferrer"
                               href='https://sonyascheer.janeapp.com'
                            >
                                <u>Booking</u>
                            </a>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}
