import React from 'react'
import TopNav from './TopNav'
import {resourceHeight} from "../util";

export default class Map extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="row" style={{marginBottom: '-5px'}}>
                <div className='col-12 p-0'style={{
                    minHeight: this.props.style.minHeight,
                    maxHeight: this.props.style.maxHeight,
                }}>
                    <iframe
                        src="https://maps.google.com/maps?q=Lincoln%20Ave,%20Wellington%20st,%20port%20coquitlam&t=&z=16&ie=UTF8&iwloc=&output=embed"
                        width='100%'
                        height={this.props.style.minHeight}
                        frameBorder="0"
                        allowFullScreen="">
                    </iframe>
                </div>
            </div>
        )
    }
}
