import React from 'react'
import RecordingIndexView from "../video/RecordingIndexView";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import { Auth } from 'aws-amplify';

export default class AuthenticationView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        // Auth.currentSession()
        //     .then(data => console.log(data))
        //     .catch(err => console.log(err));

        if (this.props.authState == "signedIn") {
            if(Date.now() > this.props.authData.signInUserSession.accessToken.payload.exp){
                return (
                    <RecordingIndexView
                        {...this.props}
                        orderBy='Topics'
                    />)
            } else {
                Auth.signOut()
                return(<Login {...this.props}/>)
            }
        } else if(this.props.authState == "signIn"){
            return(<Login {...this.props}/>)
        } else if(this.props.authState == "forgotPassword"){
            return(<ResetPassword {...this.props}/>)
        } else {
            return null
        }
    }
}
