import React from 'react'
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

export default class TopicUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.topic
        }
        this.updateTopic = this.updateTopic.bind(this)
    }

    updateTopic() {
        let categories = {}
        this.props.categories.forEach((cat) => {
            categories[cat.name] = cat.id
        })
        let id = categories[this.props.topic]
        return fetch(`${this.props.serviceUrl}/categories/${id}`, {
            credentials: 'same-origin',
            // credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.props.authData ? this.props.authData.signInUserSession.accessToken.jwtToken : ''
            },
            body: JSON.stringify({name: this.state.name}),
            method: 'put'
        }).then((response) => {
            return response.json()
        }).then((data) => {
            this.props.refresh()
        })
    }

    render() {
        return (
            <div style={{
                position: 'absolute',
                width: '100vw',
                height: '100vh',
                marginTop: 'auto',
                marginBottom: 'auto',
                backgroundColor: 'transparent',
            }}>
                <div className='row justify-content-center align-items-center h-100'>
                    <div className='col-12 col-md-10 col-lg-6 bg-white' style={{borderRadius: '15px'}}>
                        <Toolbar className='p-0'>
                            <IconButton
                                color="primary"
                                aria-label="close modal"
                                onClick={this.props.close}
                                style={{marginLeft: 'auto'}}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                        <TextField
                            className='w-100'
                            id="outlined-name"
                            label="Topic Name"
                            value={this.state.name}
                            onChange={(e) => {
                               this.setState({name: e.target.value})
                            }}
                            InputLabelProps={{shrink: true}}
                            margin="normal"
                            variant="outlined"
                        />
                        <Toolbar className='p-0'>
                            <Button
                                color='primary'
                                variant="outlined"
                                onClick={this.updateTopic}
                                style={{marginLeft: 'auto'}}
                            >
                                Update
                            </Button>

                        </Toolbar>
                    </div>

                </div>
            </div>
        )
    }
}


