import React from 'react'
import {ForgotPassword} from 'aws-amplify-react';
import Footer from "../shared/Footer";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class ResetPassword extends ForgotPassword {

    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];

        this.sendView = this.sendView.bind(this)
        this.submitView = this.submitView.bind(this)
    }

    sendView() {
        return (
            <form noValidate>
                <div className='row'>
                    <div className='col-12'>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            key="username"
                            name="username"
                            onChange={this.handleInputChange}
                            type="text"
                            label="Username"
                            autoFocus
                        />
                    </div>
                    <div className='col-12 mt-4'>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={this.send}
                        >
                            Send Code
                        </Button>
                    </div>
                    <div className='col-12 mt-4 text-right'>
                        <a href="#" variant="body2" onClick={() => super.changeState("signIn")}>
                            Back to Sign In
                        </a>
                    </div>
                </div>
            </form>
        )
    }

    submitView() {
        return (
            <form noValidate>
                <div className='row'>
                    <div className='col-12'>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="code"
                            key="code"
                            name="code"
                            onChange={this.handleInputChange}
                            type="password"
                            label="Code"
                        />
                    </div>
                    <div className='col-12'>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            key="password"
                            name="password"
                            onChange={this.handleInputChange}
                            type="password"
                            label="Password"
                            autoComplete="current-password"
                        />
                    </div>
                    <div className='col-12 mt-4'>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={() => {
                                super.submit()
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                    <div className='col-12 mt-4 text-right'>
                        <a href="#" variant="body2" onClick={() => super.changeState("signIn")}>
                            Resend Code
                        </a>
                    </div>
                </div>
            </form>
        )
    }

    render(theme) {
        const classes = makeStyles(theme => ({
            '@global': {
                body: {
                    backgroundColor: theme.palette.common.white,
                },
            },
            paper: {
                marginTop: theme.spacing(8),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            form: {
                width: '100%', // Fix IE 11 issue.
                marginTop: theme.spacing(1),
            },
            submit: {
                margin: theme.spacing(3, 0, 2),
            },
        }))

        const {authData = {}} = this.props;
        const {loading} = this.state;
        return (
            <div className='container-fluid'>
                <div className='row align-items-center sign-in'>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline/>
                        <div className={classes.paper}>
                            <Typography component="h1" variant="h5">
                                Reset your password
                            </Typography>
                            {this.state.delivery || authData.username ? this.submitView() : this.sendView()}
                        </div>
                    </Container>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: '15px',
                }}>
                    {loading && <LinearProgress style={{height: '10px', marginLeft: '-15px', marginRight: '-15px'}}/>}
                    <Footer {...this.props} style={{
                        backgroundColor: '#343a40',
                    }}/>
                </div>
            </div>
        );
    }
}

// export default withAuthenticator(Login, true);
