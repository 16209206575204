import React from 'react'
import {withRouter} from 'react-router-dom'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ShowMore from 'react-show-more';

class RecordingAllView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {recordings} = this.props
        return (
            <div className='row justify-content-center m-0' style={{
                backgroundColor: this.props.color.darkGray,
            }}>
                <div className='col-12 col-lg-10 pl-0 p-0'>
                    <div className='row m-0 pl-2 lr-2 pt-4' style={{backgroundColor: this.props.color.lightGray}}>
                        {
                            recordings.map((recording) => {
                                return (
                                    <div key={recording.id}
                                         className={`col-12 col-md-6 col-lg-4 col-xl-3 mb-5`}
                                    >
                                        <Card className='h-100'>
                                            {
                                                recording.url && recording.format == 'youtube' &&
                                                <div className='row align-content-center text-center'
                                                     style={{border: '1px solid dark-gray'}}>
                                                    <div className='col-12' style={{
                                                        position: 'relative',
                                                        paddingBottom: '56.25%',
                                                        height: 0,
                                                        // overflow: 'hidden',
                                                    }}>
                                                        <iframe
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                            src={recording.url}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                recording.url && recording.format == 'file' &&
                                                <div className='row align-content-center text-center'
                                                     style={{border: '1px solid dark-gray'}}>
                                                    <div className='col-12' style={{
                                                        position: 'relative',
                                                        paddingBottom: '56.25%',
                                                        height: 0,
                                                    }}>
                                                        <video preload="true" disablePictureInPicture controls controlsList="nodownload"
                                                               style={{
                                                                   position: 'absolute',
                                                                   top: 0,
                                                                   left: 0,
                                                                   width: '100%',
                                                                   height: '100%',
                                                               }}
                                                        >
                                                            <source src={recording.url + '#t=0.1'} type="video/mp4"/>
                                                        </video>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !recording.url &&
                                                <div className='row align-content-center text-center'
                                                     style={{height: '360px', border: '1px solid dark-gray'}}>
                                                    <div className='col-12'>
                                                        No Recording File ...
                                                    </div>
                                                </div>
                                            }
                                            <CardContent>
                                                <Typography gutterBottom>
                                                    <strong>{recording.name}</strong>
                                                </Typography>
                                                <div className='small'>
                                                    <ShowMore
                                                        lines={3}
                                                        more='Show more'
                                                        less='Show less'
                                                        anchorClass=''
                                                    >
                                                        {recording.description}
                                                    </ShowMore>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='col-12 col-lg-10 text-right pt-5 pb-5 mr-3 mr-lg-0'>
                    <a href='#' onClick={()=> {window.scrollTo(0, 0)}}>
                        <u>Back To Top</u>
                    </a>
                </div>
            </div>
        )
    }
}

export default withRouter(RecordingAllView)
